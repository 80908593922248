import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is Scala Developer?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Scala is a general-purpose programming language that offers support for functional programming and a powerful static type system. Scala source code is intended to be compiled to Java bytecode to allow the resulting executable code to run on the Java virtual machine."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Why Do People Use Scala?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Scala offers the best dynamic and static languages. Scala offers type interference functions and variables, much better than limited type interference C# and Java. It also offers a compiler that uses type reference to a full extent."
        }
    }
}

const ScalaDevelopment = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Scala Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Scala Developers. Top
                                    companies and start-ups choose Codestaff professional Scala
                                    Developers for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Scala Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE SCALA DEVELOPERS' banner='/scala-development.png' bannerAlt='scala development banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default ScalaDevelopment