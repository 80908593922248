import React from 'react'
import Layout from '../components/layout'
import ScalaDevelopment from '../components/categories/scala-development'
import Seo from '../components/seo'

const ScalaDevelopmentPage = ({ location }) => {
  return (
    <Layout location={location}>
      <ScalaDevelopment />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Scala Development Freelancers | Codestaff'
  const description =
    'Hire the best Scala Development freelancers at Codestaff. Get the top 1% of Scala Development professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default ScalaDevelopmentPage
